<template>
    <div>
        <section style="background: black;">
            <!-- Place somewhere in the <body> of your page -->
            <div class="flexslider" style="border:0; margin:0">
                <ul class="slides">
                    <li>
                        <img src="assets/img/home/bg-1.jpg" />
                    </li>
                    <li>
                        <img src="assets/img/home/bg-2.jpg" />
                    </li>
                </ul>
            </div>
            <div class="overlay">
                <div class="container">
                    <div class="row align-center">
                        <div class="col-md-6 text-left">
                            <div class="logo">
                                <nav class="navbar navbar-expand-lg navbar-light">
                                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <img src="assets/img/menu.svg">
                                    </button>
                                    <a class="navbar-brand" href="javascript:void(0)">
                                        <img src="assets/img/logo.svg">
                                    </a>
                                </nav>
                            </div>
                        </div>
<!--                        <div class="col-md-6 text-right">-->
<!--                            <ul class="navbar-desktop">-->
<!--                                <li><router-link class="menu-item" :to="route + 'contacto'">{{ $t("contact_us")}}</router-link></li>-->
<!--                            </ul>-->
<!--                        </div>-->
                    </div>
                </div>
                <div class="main-elements">
                    <div class="container">
                        <div class="row">
<!--                            <div class="collapse navbar-collapse" id="navbarSupportedContent">-->
<!--                                <ul class="navbar-nav mr-auto">-->
<!--                                    <li class="nav-item">-->
<!--                                        <router-link :to="route + 'contacto'" class="nav-link menu-item	" href="#">{{ $t("contact_us")}}</router-link>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                            <div class="col-md-8 self-center">
                                <h1>{{ $t("slogan_1_2") }} <span>MCCAIN</span> {{ $t("slogan_2_2") }}</h1>
                            </div>
                            <div class="col-md-4 self-center init-sesion">
                                <div class="white-box">
                                    <div class="text-center" v-if="!enable_login" >
                                        <h4>{{ $t("form_country_select") }}</h4>
                                        <form @submit.prevent="onSubmit">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <select class="select-input" name="comunicacion" v-model="country" placeholder="{{ $t('form_country_select') }}">
                                                        <option v-for="(country,index) in this.countries" :key="index" :value="country">{{country}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <p class="password-forget d-none country-required" style="color: red;">{{ $t("errors.country_required") }}</p>
                                            <button v-if="!this.loading" class="pointer" @click="setCountry">{{ $t("buttons.continue") }}</button>
                                            <button v-if="this.loading" class="pointer text-center">
                                                  <div class="spinner"></div>
                                            </button>
                                        </form>
                                    </div>
                                    <div class="text-center" v-if="enable_login">
                                        <h4>{{ $t("form_login_title") }}</h4>
                                        <form @submit.prevent="onSubmit">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <input v-model="usuario" type="text" name="" :placeholder="$t('form_user')">
                                                </div>
                                                <div class="col-md-12">
                                                    <input v-model="password" type="password" :placeholder="$t('form_password')" name="">
                                                </div>
                                            </div>
                                            <router-link :to="route + 'forgot-password'">
                                                <p v-if="this.country != 'Argentina'" class="password-forget">{{ $t("form_forgot_password") }}</p>
                                            </router-link>
                                            <p class="password-forget d-none invalid-password" style="color: red;">{{ $t("errors.user_or_pass_invalid") }}</p>
                                            <p class="password-forget d-none invalid-country" style="color: red;">{{ $t("errors.invalid_country") }}</p>
                                            <router-link :to="route">
                                                <p class="password-forget d-none invalid-country">{{ $t("back_to_country_selection") }}</p>
                                            </router-link>
                                            <button v-if="!this.loading" class="pointer" @click="login">{{ $t("buttons.login") }}</button>
                                            <button v-if="this.loading" class="pointer text-center">
                                                  <div class="spinner"></div>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterCopyright></FooterCopyright>
    </div>
</template>

<script>
    import FooterCopyright from '../layouts/FooterCopyright.vue'
    import axios from 'axios'
    import { route } from "../../enviroment.js"
    import $ from 'jquery'

    export default {
        name: 'IngresoComponent',
        i18n: {
            messages: {
                en: {
                    contact_us: "Contact Us",
                    form_country_select: "Select country",
                    form_login_title: "Login",
                    form_forgot_password: "¿Forgot password?",
                    form_user: "User",
                    form_password: "Password",
                    slogan_1_2: "Discover everything that",
                    slogan_2_2: "has for you",
                    errors: {
                        country_required: "Country is required",
                        user_or_pass_invalid: "Invalid User or Password",
                        invalid_country: "Invalid Country selected."
                    },
                    back_to_country_selection: "Back to country selection"
                },
                es: {
                    contact_us: "Contáctenos",
                    form_country_select: "Seleccione pais",
                    form_login_title: "Iniciar Sesión", 
                    form_forgot_password: "¿Olvidó su contraseña?",
                    form_user: "Usuario",
                    form_password: "Contraseña",
                    slogan_1_2: "Descubre todo lo que",
                    slogan_2_2: "tiene para usted",
                    errors: {
                        country_required: "El pais es requerido",
                        user_or_pass_invalid: "Usuario o contraseña invalidos",
                        invalid_country: "Pais seleccionado invalido."
                    },
                    back_to_country_selection: "Seleccionar pais"
                }
            }
        },
        data: function () {
            return {
                usuario: '',
                password: '',
                country: null,
                countries: [
                    'Argentina',
                    'Calatin',
                    'Colombia',
                    'Mexico',
                    'Uruguay',
                    'Chile'
                ],
                route : route,
                enable_login: false,
                loading: false
            }
        },
        created() {
            this.getCountry()
        },
        components: {
            FooterCopyright
        },
        methods: {
            getCountry() {
                this.country = this.$cookies.get("pwa-woe-country");
            },
            setCountry() {
                this.loading = true
                const country = this.country;
                axios.post(this.$woe_api_uri + '/country',
                    {
                        "country": country,
                    },
                ).then(async (response) => {
                    this.enable_login = true;
                    this.loading = false
                    sessionStorage.setItem('country', response.data.data.country);
                    this.$cookies.set("pwa-woe-country", response.data.data.country);
                    this.country = this.$cookies.get("pwa-woe-country");
                }).catch(() => {
                    $(".country-required").removeClass("d-none")
                }); 
            },
            login() {
                this.loading = true
                axios.post(this.$woe_api_uri + '/login',
                    {
                        "email": this.usuario,
                        "password": this.password
                    }
                ).then(async ()=> {
                    const user = await this.getUser();
                    sessionStorage.setItem('pwa-woe-user-profile', JSON.stringify(user));
                    sessionStorage.setItem('pwa-woe-user-type', JSON.stringify(user.user_type));
                    const name = user.user_type === 'person' ? user.firstname : user.name;
                    sessionStorage.setItem('name', JSON.parse(JSON.stringify(name)));
                    location.href = user.user_type === 'person' ? 'sold-tos' : route;
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                    const message = error?.response?.data?.error?.message;
                    if (message === 'Incorrect country.') {
                        $(".invalid-country").removeClass("d-none");
                    } else {
                        $(".invalid-password").removeClass("d-none");
                    }
                });
            },
            getUser() {
                return axios.get(this.$woe_api_uri + '/user', {}
                ).then((response) => {
                    this.$cookies.set("pwa-woe-language", response.data.data.user.default_language);
                    return response.data.data.user;
                })
                .catch((error) => {
                    if (error.code === "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    } else {
                        this.showAlert('warning',`${this.$t('caution')}!`, error.response.data.error.message);
                    }
                });
            },
        }

    }
</script>